import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {
	InMemoryScrollingOptions,
	provideRouter,
	withComponentInputBinding,
	withHashLocation,
	withInMemoryScrolling,
	withViewTransitions,
} from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HeadersInterceptor } from '@core/interceptor/headers-interceptor';
import { TokenInterceptor } from '@core/interceptor/token-interceptor';

import { LocalizedDatePipe } from '@shared/pipes';
import {HttpMockRequestInterceptor} from "@interceptor/mock-interceptor/mock.interceptor";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {provideToastr} from "ngx-toastr";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {MatPaginatorIntlDe} from "@shared/services/paginator-intl-de";

const scrollConfig: InMemoryScrollingOptions = {
	scrollPositionRestoration: 'enabled',
	anchorScrolling: 'disabled',
};
const isIE =
	window.navigator.userAgent.indexOf('MSIE ') > -1 ||
	window.navigator.userAgent.indexOf('Trident/') > -1;

export const MY_DATE_FORMAT = {
	parse: {
		dateInput: ['YYYY-MM-DD', 'DD.MM.YYYY', 'DDMMYYYY'] // this is how your date will be parsed from Input
	},
	display: {
		dateInput: 'DD.MM.YYYY',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	}
};

export const appConfig: ApplicationConfig = {
	providers: [
		{
			provide: LOCALE_ID,
			useValue: 'de-DE'
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
		{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe },
		provideRouter(
			routes,
			withComponentInputBinding(),
			withViewTransitions(),
			withInMemoryScrolling(scrollConfig),
			withHashLocation(),
		),
		provideAnimations(),
		provideHttpClient(withInterceptors([HeadersInterceptor, HttpMockRequestInterceptor, TokenInterceptor])),
		LocalizedDatePipe,
		importProvidersFrom(
			TranslateModule.forRoot({
				defaultLanguage: 'de',
				loader: {
					provide: TranslateLoader,
					useFactory: (httpHandler: HttpBackend) => {
						return new TranslateHttpLoader(
							new HttpClient(httpHandler),
							'./assets/i18n/',
						);
					},
					deps: [HttpBackend],
				},
			})
		),
		provideAnimations(), // required animations providers
		provideToastr(), // Toastr providers
	],
};
