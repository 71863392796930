import { Routes } from '@angular/router';
import { Injector } from '@angular/core';
import { TRouteAccess } from '@shared/interfaces/route.interface';
import { AppRouteService } from '@shared/services/app-route.service';

const injector = Injector.create({
	providers: [{ provide: AppRouteService, useClass: AppRouteService }],
});
const appRouteService = injector.get(AppRouteService);
const routeAccess: TRouteAccess = appRouteService.routeAccess;

export const routes: Routes = [
	{ path: '', redirectTo: appRouteService.defaultRoute, pathMatch: 'full' },
	{
		path: routeAccess.landing.routeConfig.path,
		loadComponent: () => import('@pages/landing/landing.component').then(mod => mod.LandingComponent),
		canActivate: routeAccess.landing.canActivate,
		data: {},
	},
	{
		path: routeAccess.login.routeConfig.path,
		loadComponent: () => import('@pages/auth/login/login.component').then(mod => mod.LoginComponent),
		canActivate: routeAccess.login.canActivate,
		data: {},
	},
	{
		path: routeAccess.register.routeConfig.path,
		canActivate: routeAccess.register.canActivate,
		loadComponent: () => import('@pages/auth/register/register.component').then(r => r.RegisterComponent),
	},
	{
		path: routeAccess.confirm.routeConfig.path,
		canActivate: routeAccess.confirm.canActivate,
		loadComponent: () => import('@pages/auth/confirm/confirm.component').then(r => r.ConfirmComponent),
	},
	{
		path: routeAccess.almostDone.routeConfig.path,
		canActivate: routeAccess.almostDone.canActivate,
		loadComponent: () => import('@pages/auth/almost-done/almost-done.component').then(r => r.AlmostDoneComponent),
	},
	{
		path: routeAccess.forgotPassword.routeConfig.path,
		canActivate: routeAccess.forgotPassword.canActivate,
		loadComponent: () => import('@pages/auth/forgot-password/forgot-password.component').then(r => r.ForgotPasswordComponent),
	},
	{
		path: routeAccess.resetPassword.routeConfig.path,
		canActivate: routeAccess.resetPassword.canActivate,
		loadComponent: () => import('@pages/auth/reset-password/reset-password.component').then(r => r.ResetPasswordComponent),
	},
	{
		path: routeAccess.settings.routeConfig.path,
		canActivate: routeAccess.settings.canActivate,
		loadChildren: () => import('@pages/settings/settings.routes').then(r => r.routes),
	},
	{
		path: routeAccess.petitions.routeConfig.path,
		canActivate: routeAccess.petitions.canActivate,
		loadChildren: () => import('@pages/petitions/petitions.routes').then(r => r.routes),
	},
	{
		path: routeAccess.meetings.routeConfig.path,
		canActivate: routeAccess.meetings.canActivate,
		loadChildren: () => import('@pages/meetings/meetings.routes').then(r => r.routes),
	},
	{
		path: routeAccess.userManagement.routeConfig.path,
		canActivate: routeAccess.userManagement.canActivate,
		loadChildren: () => import('@pages/user-management/user-management.routes').then(r => r.routes),
	},
	{
		path: routeAccess.profile.routeConfig.path,
		canActivate: routeAccess.profile.canActivate,
		loadChildren: () => import('@pages/profile/profile.routes').then(r => r.routes),
	},
	{
		path: routeAccess.missingRights.routeConfig.path,
		loadComponent: () => import('@pages/missing-rights/missing-rights.component').then(mod => mod.MissingRightsComponent),
		canActivate: routeAccess.landing.canActivate,
	},

	{ path: '**', redirectTo: appRouteService.defaultRoute },
];
