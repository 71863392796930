import { MockRoutes } from '@interceptor/mock-interceptor/mock.server.config';

import * as me from '@mock/me.json';
import * as allCharities from '@mock/charities_reduced.json';
import * as allPetitions from '@mock/all_petitions.json';
import * as allMeetings from '@mock/all_meetings.json';
import * as allUsers from '@mock/all_users.json';



var routes = new MockRoutes();
//routes.get('Users/me').respond(me);
//routes.get('Charity').respond(allCharities);
//routes.get('Petition').respond(allPetitions);
//routes.get('Meeting').respond(allMeetings);
//routes.get('Charity/users').respond(allUsers);


export const routing = routes.export();
